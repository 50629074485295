/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { Heading } from "@chakra-ui/react"
import { config } from "../../config"

export function Logo() {
  return (
    <Heading marginY={0} fontSize={["sm", "xl"]}>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <span
        css={css`
          font-size: 160%;
          vertical-align: middle;
          margin-right: 0.2em;
          line-height: 0;
          display: inline-block;
          margin-top: -0.1em;
        `}
      >
        {config.SITE_EMOJI}
      </span>
      <span
        style={{
          letterSpacing: "-.02em",
        }}
      >
        {config.SITE_NAME}
      </span>
    </Heading>
  )
}

import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { config } from "../../config"

export const PageTitle: FC = ({ children }) => {
  return (
    <Helmet>
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      {children ? (
        <title>
          {children} • {config.SITE_NAME}
        </title>
      ) : (
        <title>{config.SITE_NAME}</title>
      )}
    </Helmet>
  )
}

import React from "react"
import {
  FormControl,
  FormLabel,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import { centerStyle } from "./layout/Nav"

export function ToggleDarkModeSwitch() {
  const { colorMode, setColorMode } = useColorMode()

  return (
    // Doing `auto` to prevent it from squeezing out other elements
    <FormControl
      display="flex"
      alignItems="center"
      width="auto"
      alignSelf="stretch"
    >
      <FormLabel
        htmlFor="dark-mode-switch"
        mb="0"
        mr={2}
        alignSelf="stretch"
        style={centerStyle}
      >
        <Text fontSize="sm" fontWeight="semibold">
          Dark Mode
        </Text>
      </FormLabel>
      <Switch
        size="sm"
        colorScheme="green"
        id="dark-mode-switch"
        isChecked={colorMode === "dark"}
        onChange={e => setColorMode(e.target.checked ? "dark" : "light")}
      />
    </FormControl>
  )
}

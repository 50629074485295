import React, { FC } from "react"
import { Alert, AlertIcon, Box, BoxProps, Container } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { ErrorBoundary } from "../ErrorBoundary"

export const LayoutBody: FC<
  BoxProps & {
    size?: BoxProps["width"]
    isPadded?: boolean
  }
> = ({ children, size = "2xl", isPadded = true, ...rest }) => (
  <Box
    maxW={size}
    mx="auto"
    px={isPadded ? 4 : 0}
    pt="calc(10vmin)"
    pb="calc(20px + 10vw)"
    css={css`
      width: 100%;
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      flex-grow: 1;
    `}
    {...rest}
  >
    <noscript>
      <Container>
        <Alert status="error">
          <AlertIcon />
          Enable JavaScript
        </Alert>
      </Container>
    </noscript>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Box>
)

import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  SystemProps,
  Text,
  Tooltip,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react"
import { useMatch } from "@reach/router"
import { Link as GatsbyLink, navigate } from "gatsby"
import React, { FC, forwardRef } from "react"
import { useAuthenticated } from "../auth/Authenticated"
import { Authenticated2 } from "../auth/Authenticated2"
import { Confirm } from "../Confirm"
import { Logo } from "./Logo"
import { ToggleDarkModeSwitch } from "../ToggleDarkModeSwitch"
import {
  RiArrowDownSLine,
  RiBookOpenLine,
  RiDoorClosedLine,
  RiLineChartLine,
  RiLogoutBoxLine,
  RiStarLine,
} from "react-icons/ri"
import { css } from "@emotion/react"

// This is part of a series of changes to the nav to expand the
// hit area of buttons and links in the nav bar
export const centerStyle = {
  display: "flex",
  alignItems: "center",
}

// Maybe make it use chakra props like `variant="withShadow"`
// https://chakra-ui.com/docs/theming/customize-theme
const NavWrapper: FC<FlexProps & { showBottomBorder: boolean }> = ({
  children,
  showBottomBorder,
  ...props
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.700")

  return (
    <Flex
      // Don't use rem because I want to size based on text, despite Chakra using rem for sizing and spacing
      height="3.5em"
      mx={4}
      alignItems="stretch"
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor={showBottomBorder ? borderColor : "transparent"}
      {...props}
    >
      {children}
    </Flex>
  )
}

const NavStack: FC<{ spacing?: SystemProps["margin"] }> = ({
  spacing,
  children,
}) => {
  return (
    <HStack spacing={spacing} alignItems="stretch">
      {children}
    </HStack>
  )
}

const ItemWrapper: FC<FlexProps & { activeUrl?: string }> = ({
  children,
  activeUrl,
  ...rest
}) => {
  // Won't expect activeUrl to change for a given component
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const match = activeUrl && useMatch(activeUrl)
  const bgToken = useColorModeValue("white", "gray.800")
  const bgColor = useToken("colors", bgToken)
  const borderToken = useColorModeValue("gray.200", "gray.700")
  const borderColor = useToken("colors", borderToken)
  const [w, h] = [15, 15]

  return (
    <Flex align="center" position="relative" {...rest}>
      <Box
        position="absolute"
        height="1px"
        // -1px to line up with bottom border of nav
        bottom="-1px"
        width="100%"
        // bg={match ? "currentColor" : undefined}
      />
      {match && (
        <svg
          viewBox={`0 0 ${w} ${h}`}
          width={w}
          height={h}
          style={{
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          <path
            fill={bgColor}
            stroke={borderColor}
            strokeWidth="1"
            d={`
            M 0,0
            L ${w / 2},${h / 2}
            L ${w},0
          `}
          />
        </svg>
      )}
      {children}
    </Flex>
  )
}

function AccountButton() {
  const { user } = useAuthenticated()

  async function handleSignOut() {
    try {
      navigate("/account/login", { state: { signOut: true } })
    } catch (err) {
      alert("Sign out failed")
      throw err
    }
  }

  return (
    // Surrounding with <div> allows menu list to display in correct position
    // Before, the menu would open up and not align with the button that triggered it
    <ItemWrapper activeUrl="/account-settings/*">
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          size="sm"
          variant="ghost"
          // leftIcon={<Avatar size="xs" />}
          rightIcon={<Icon as={RiArrowDownSLine} />}
          mr={-2}
        >
          {user.email}
        </MenuButton>
        <MenuList>
          {/* <MenuItem
            as={GatsbyLink}
            to="/"
            // eslint-disable-next-line jsx-a11y/accessible-emoji
            icon={<Text fontSize="lg">⌨️</Text>}
          >
            Typing Test
          </MenuItem> */}
          <MenuItem
            as={GatsbyLink}
            to="/account-settings"
            // eslint-disable-next-line jsx-a11y/accessible-emoji
            icon={<Text fontSize="lg">⚙️</Text>}
          >
            Account Settings
          </MenuItem>
          {/* <MenuItem
            as={GatsbyLink}
            to="/account/stats"
            // eslint-disable-next-line jsx-a11y/accessible-emoji
            icon={<Text fontSize="lg">📊</Text>}
          >
            Stats
          </MenuItem> */}
          <MenuDivider />
          <Confirm
            onConfirm={handleSignOut}
            title="Are you sure you want to sign out?"
          >
            {openDialog => (
              <MenuItem
                // eslint-disable-next-line jsx-a11y/accessible-emoji
                icon={<b>←</b>}
                onClick={openDialog}
              >
                <Text fontWeight="bold">Sign Out</Text>
              </MenuItem>
            )}
          </Confirm>
        </MenuList>
      </Menu>
    </ItemWrapper>
  )
}

function LogoLink() {
  return (
    <ItemWrapper activeUrl="/">
      <GatsbyLink to="/">
        <Logo />
      </GatsbyLink>
    </ItemWrapper>
  )
}

export const LoadingNav: FC = () => (
  <NavWrapper showBottomBorder>
    <LogoLink />
    <Spacer />
    <ItemWrapper>
      <Spinner size="sm" />
    </ItemWrapper>
  </NavWrapper>
)

export const BareNav: FC = () => (
  <NavWrapper showBottomBorder>
    <LogoLink />
    <Spacer />
    <ItemWrapper>
      <ToggleDarkModeSwitch />
    </ItemWrapper>
  </NavWrapper>
)

const CustomButton = forwardRef(function CustomButton(
  { onMouseEnter, ...props }: any,
  ref
) {
  return (
    <Box ref={ref} onMouseEnter={props.isDisabled ? onMouseEnter : undefined}>
      <Button
        {...props}
        onMouseEnter={props.isDisabled ? undefined : onMouseEnter}
      />
    </Box>
  )
})

const NavLink: FC<any> = ({ to, isDisabled, remixIcon, children }: any) => {
  return (
    <ItemWrapper activeUrl={to}>
      <Tooltip
        hasArrow
        label="Sign up to access this page."
        isDisabled={!isDisabled}
      >
        <CustomButton
          // Need to turn off link because otherwise `to` prop also needs to be set
          as={isDisabled ? undefined : GatsbyLink}
          // Ideally, would be `undefined`, not `#`, but then tooltip won't show up
          to={isDisabled ? undefined : to}
          size="lg"
          variant="ghost"
          fontWeight="medium"
          // Need to visually adjust the remix icon
          isDisabled={isDisabled}
          leftIcon={<Icon transform="translateY(-0.1em)" as={remixIcon} />}
        >
          {children}
        </CustomButton>
      </Tooltip>
    </ItemWrapper>
  )
}

function AuthNav({ showBottomBorder }: { showBottomBorder: boolean }) {
  return (
    <NavWrapper showBottomBorder={showBottomBorder}>
      <NavStack>
        <LogoLink />
        <Box pl={4} />

        {/* <NavLink to="/typing-practice" remixIcon={RiBookOpenLine}>
          Practice
        </NavLink> */}

        {/* <NavLink to="/typing-test" remixIcon={RiStarLine}>
          Test
        </NavLink> */}

        <NavLink to="/account/stats" remixIcon={RiLineChartLine}>
          Stats
        </NavLink>
      </NavStack>
      <Spacer />
      <NavStack>
        <ItemWrapper>
          {/* <Button
            size="sm"
            variant="outline"
            rightIcon={<Icon as={RiArrowDownSLine} />}
          >
            Preferences
          </Button> */}
        </ItemWrapper>
        <ItemWrapper display={["none", "none", "flex"]}>
          <ToggleDarkModeSwitch />
        </ItemWrapper>

        <AccountButton />
      </NavStack>
    </NavWrapper>
  )
}

function NoAuthNav({ showBottomBorder }: { showBottomBorder: boolean }) {
  return (
    <NavWrapper showBottomBorder={showBottomBorder}>
      <NavStack>
        <LogoLink />
        <Box pl={4} />
        {/* <NavLink to="/typing-practice" remixIcon={RiBookOpenLine} isDisabled>
          Practice
        </NavLink> */}

        {/* <NavLink to="/typing-test" remixIcon={RiStarLine} isDisabled>
          Test
        </NavLink> */}

        <NavLink to="/account/stats" remixIcon={RiLineChartLine} isDisabled>
          Stats
        </NavLink>
      </NavStack>
      <Spacer />
      <NavStack>
        <ItemWrapper display={["none", "none", "flex"]}>
          <ToggleDarkModeSwitch />
        </ItemWrapper>
        <ItemWrapper>
          <Button as={GatsbyLink} to="/account/login" size="sm" variant="ghost">
            Log In
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Button
            as={GatsbyLink}
            to="/account/signup"
            size="sm"
            colorScheme="green"
          >
            Create Account
          </Button>
        </ItemWrapper>
      </NavStack>
    </NavWrapper>
  )
}

export const Nav: FC<{ showBottomBorder: boolean }> = ({
  showBottomBorder,
}) => {
  return (
    <Authenticated2
      loading={<LoadingNav />}
      auth={<AuthNav showBottomBorder={showBottomBorder} />}
      noAuth={<NoAuthNav showBottomBorder={showBottomBorder} />}
    />
  )
}
